<template lang="pug">
    .address-create
        van-cell-group.group
            van-field(v-model="address.senderName" label="姓名" placeholder="寄件人姓名" )
            van-field(v-model="address.mobile" label="电话" placeholder="寄件人电话" )
            van-field(v-model="address.fullAddress" label="地区" placeholder="选择省/市/区" is-link)
            van-field(v-model="address.address" label="详细地址" placeholder="街道门牌、楼层房间号等" )
        van-cell-group.mt12
            van-cell(title="设为默认发货地址" center )
                template(#right-icon)
                    van-switch(v-model="address.isDefault" size="24" active-color="#dd4433")
        FooterButton(:title="'保存'" @save="onSave")
</template>

<script>
  import {Toast} from 'vant';
  import FooterButton from '../common/footerButton'

  export default {
    components:{
      FooterButton
    },
    data() {
      return {
        address:{},
        areaList: {},
      };
    },
    methods: {
      onSave() {
        Toast('save');
      },
    },
  };
</script>

<style lang="stylus">
    .address-create
        padding 12px
    .group
        border-radius 8px !important
</style>
